import { CheckList } from './../main/model/checklist';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckListNode } from '../main/model/checklist_node';
import { MedCarService } from '../services/med-car.service';

@Component({
  selector: 'app-field-check-cri',
  templateUrl: './field-check-cri.component.html',
  styleUrls: ['./field-check-cri.component.css']
})
export class FieldCheckCriComponent implements OnInit {
  
  @Input() value?: boolean;
  @Output() valueChange = new EventEmitter<boolean|null>();

  @Input() title: string = "";
  @Input() name: string = "";
  @Input() disabled: boolean = false;
  @Input() node: CheckListNode;
  @Input() parentNode: CheckListNode;
  @Input() fullCheckList: CheckList;

  valoreOK?: boolean = false;
  valoreFalse?: boolean = false;
  btnColor?: string;

  constructor(
    private medCarService: MedCarService
  ) { 
  }

  ngOnInit(): void {

  }
  
  valueChanged(newValue: boolean|null){
     this.valueChange.emit(newValue);
  }
  
  getValue(v:boolean | undefined){
    console.log(v);
  }

  clickYES(){
      this.value=true;
      this.valueChanged(this.value);      
     // this.medCarService.checkListValues2(this.node, this.fullCheckList);
      this.medCarService.checkListValues(this.node, this.fullCheckList);
  }
  
  clickNO(){
      this.value=false;
      this.valueChanged(this.value);
    //  this.medCarService.checkListValues2(this.node, this.fullCheckList);
    this.medCarService.checkListValues(this.node, this.fullCheckList);
  }

}
