import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WDFormFieldsModule } from './modules/ngcomponents/formfields/formfields.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WDAppComponentsModule } from './modules/ngcomponents/appcomponents/wd.appcomponents.module';
import { HeaderCRIComponent } from './header-cri/header-cri.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { FieldCheckCriComponent } from './field-check-cri/field-check-cri.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NavTabComponent } from './nav-tab/nav-tab.component';
import { HttpClientModule } from '@angular/common/http';



import { FooterCriComponent } from './footer-cri/footer-cri.component';
import { FieldSelectCriComponent } from './field-select-cri/field-select-cri.component';
import { FieldTextCriComponent } from './field-text-cri/field-text-cri.component';
import { BackOfficeModuleModule } from './back-office-module/back-office-module.module';
import { FormControlComponent } from './form-control/form-control.component';
import { LoginComponent } from './login/login.component';
import { ResultComponent } from './result/result.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FieldTextCriNoComponent } from './field-text-no-cri/field-text-no-cri.component';

@NgModule({
  declarations: [				
    AppComponent,
    HeaderCRIComponent,
    MainComponent,
    HomeComponent,
    FieldCheckCriComponent,
    NavTabComponent,
    FieldTextCriNoComponent,
    
    FooterCriComponent,
    FieldSelectCriComponent,
    FieldTextCriComponent,
      FormControlComponent,
      LoginComponent,
      ResultComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    WDFormFieldsModule,
    WDAppComponentsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BackOfficeModuleModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
