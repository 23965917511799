import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BackOfficeService } from '../backOffice.service';
import { OptionList } from 'src/app/main/model/options';


@Component({
  selector: 'app-newVehicle',
  templateUrl: './newVehicle.component.html',
  styleUrls: ['./newVehicle.component.css']
})
export class NewVehicleComponent implements OnInit {


  vehicles: OptionList[] = [];

  constructor(private backOfficeService: BackOfficeService) { }

  
  ngOnInit(): void {
    this.vehicles = this.backOfficeService.vehicleType;
  }

}
