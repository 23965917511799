<!-- AutomedComponent diventa il componente responsabile per la gestione dei dati di automedica
quindi è in questo componente che inseriamo anche la navtab e il footer ai quali passiamo solo dei parametri
senza replicare la gestione della lista -->
<app-nav-tab [menuTab]="campi" (changeTab)="getNodeByName($event)" [selectedItem]="indice"></app-nav-tab>
<!-- In app-form-control passiamo come parametro solo il nodo, che ricaviamo in automedcomponent -->
<app-form-control 
    [menuTab]="campi" 
    [node]="node" 
    [activeTab]="this.activeTab" 
    [parentNode]="parentNode"
    [fullCheckList]="check"
    (changeTab2)="getNodeByName($event)" 
    ></app-form-control>
<!-- <app-footer-cri [menuTab]="campi" [activeTab]="activeTab" (changeTab2)="getNodeByName($event)"></app-footer-cri> -->
<div class="row justify-content-center">
    <button class="btn btn-success" type="submit" (click)="onValueChange()" [disabled]="!canSubmit" routerLink="/result" >submit</button>
</div>
