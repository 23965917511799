import { CheckList } from './../main/model/checklist';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckListNode } from '../main/model/checklist_node';
import { MedCarService } from '../services/med-car.service';

@Component({
  selector: 'app-field-select-cri',
  templateUrl: './field-select-cri.component.html',
  styleUrls: ['./field-select-cri.component.css']
})
export class FieldSelectCriComponent implements OnInit {

  selected: any;
  @Input() options: {name: string, value: any}[] = [];
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() node: CheckListNode;
  @Input() parentNode: CheckListNode;
  @Input() value = "";
  @Input() fullCheckList: CheckList;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private medCarService: MedCarService
  ) { }
  
  ngOnInit(): void {
    this.selected=this.value;
  }

  onValueChanged(selection: string) {
    this.valueChange.emit(selection);
   // this.medCarService.checkListValues2(this.node, this.fullCheckList);
    this.medCarService.checkListValues(this.node, this.fullCheckList);
  }  
}
