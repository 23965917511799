import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wd-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.css']
})
export class FieldSelectComponent implements OnInit {

  @Input() selected: any;
  @Input() options: {label: string, value: any, disabled?: boolean}[] = [];
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() errorText?: string;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  
  ngOnInit(): void {
  }

}
