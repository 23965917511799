import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home.service';
import { OptionList } from '../main/model/options';
import { HomeForm } from '../main/model/homeForm';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  opzioniMezzi: OptionList[] = [];
  opzioniTurni: OptionList[] = [];
  opzioniAutista: OptionList[] = [];
  opzioniSocc: OptionList[] = [];
  dataTurno!: Date;
  ambCond = true;
  opMezzi = false;
  homeResult: HomeForm = new HomeForm();
  route?: string;

  constructor(private homeService: HomeService) { }

  ngOnInit(){
    this.opzioniMezzi = this.homeService.vehicleOptions;
    this.opzioniTurni = this.homeService.shiftOptions;
    this.opzioniAutista = this.homeService.driverOptions;
    this.opzioniSocc = this.homeService.rescuerOptions;

  }

  Confronto(){
    this.route=this.homeResult.automezzo
    if(this.homeResult.automezzo === "automedica")
    {
      this.ambCond = false;
      this.opMezzi = true;
      
    }else{
      this.ambCond = true;
      this.opMezzi = true;
    }
  }
  onsubmit(){
    console.log(this.homeResult);
    this.homeService.resultHomeForm.push(this.homeResult);
    console.log(this.homeService.resultHomeForm)
  }
  log(scelta: string){
    console.log(scelta);
  }
}
