import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WDFormFieldsModule } from '../modules/ngcomponents/formfields/formfields.module';


import { NewVehicleComponent } from './newVehicle/newVehicle.component';
import { AdminComponent } from './admin/admin.component';
import { FormsModule } from '@angular/forms';
import { NewRescuerComponent } from './newRescuer/newRescuer.component';




@NgModule({
  declarations: [
    NewVehicleComponent,
    AdminComponent,
    NewRescuerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    WDFormFieldsModule
    
  ]
})
export class BackOfficeModuleModule { }
