<table>
  <tr>
    <td scope="row" class="pl-2">{{title}}</td>
    <td class="col-md-2 col-sm-1 p-1 text-center">
      <button
        [ngClass]="value === true ? 'btn btn-success btn-sm' : 'btn btn-outline-success btn-sm'"
        (click)="clickYES()"> OK
      </button>
    </td>
    <td class="col-md-2 col-sm-1 p-1 text-center">
      <button
        [ngClass]="value === false ? 'btn btn-danger btn-sm' : 'btn btn-outline-danger btn-sm'"
        (click)="clickNO()"> NO
      </button>
    </td>
  </tr>
</table>
