<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div class="container-fluid">
      <ul class="nav nav-tabs mr-auto navChar"> 
        <!--simple menu-->
        <li class="nav-item" *ngFor="let menu of menuTab; let i = index">
          <a
            class="nav-link"
            *ngIf="!menu.isDropDownMenu"
            (click)="tabChange(menu.name); selectedItem = i"   
            [ngClass]="getClass(i, menu)"
            role="button"
            >{{menu.name}}
           </a>     
          <!--dropdown menu-->
              <div ngbDropdown class="nav-item">
                <a
                  [ngClass]="getClass(i, menu)"
                  class="nav-link"
                  ngbDropdownToggle
                  id="{{ menu.name }}"
                  *ngIf="menu.isDropDownMenu"
                  role="button"
                  (click)="selectedItem = i">
                  {{ menu.name }}
                </a>
                  <div ngbDropdownMenu class="navChar">
                    <a *ngFor="let submenu of menu.childNodes" ngbDropdownItem  
                       (click)="tabChange(submenu.name)"
                       [ngClass]="getClass(null, submenu)">
                      {{submenu.name}}</a>
                  </div>
              </div>   
          <!--fine dropdown menu-->
        </li>
      </ul>
    </div>
  </div>
</nav>

