<table>
    <tbody>
        <tr>
            <td scope="row"
                class="pl-2">
                {{title}}
            </td>
            <td class="col-md-4 col-sm-2 p-1"
                rowspan="2">
                <div
                    class="form-group text-center mb-0"
                    *ngIf="options.length">
                    <select
                        class="form-control form-control-sm"
                        (change)="onValueChanged(selected)"
                        [(ngModel)]="selected">
                        <option
                            *ngFor="let option of options"
                            [ngValue]="option.value">
                            {{option.name}}
                        </option>
                    </select>
                </div>
            </td>
        </tr>
    </tbody>
</table>
