import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wd-field-date',
  templateUrl: './field-date.component.html',
  styleUrls: ['./field-date.component.css']
})
export class FieldDateComponent implements OnInit {

  /**holds current value of input */
  @Input() value?: Date;
  @Input() errorText?: string;

  /**invoked when model has been changed
   */
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() title: string = "";
  @Input() disabled: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
