import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackOfficeService {

constructor() { }

  scelta = [{label: "Nuovo automezzo", value: 1},
  {label: "Nuovo operatore", value: 2}]

  vehicleType = [
    {label: "Autoambulanza", value: 1},
    {label: "Automedica", value: 2}
  ]

}
