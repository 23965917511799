import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { HomeService } from "../services/home.service";
import { MedCarService } from "../services/med-car.service";
import { CheckList } from "./model/checklist";
import { CheckListNode } from "./model/checklist_node";
import { HomeForm } from "./model/homeForm";

@Component({
	selector: "app-main",
	templateUrl: "./main.component.html",
	styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit, OnDestroy{
	//inizializzazione degli oggetti Checklist e ChecklistNode
	//il primo conterrà l'intera checklist di automezzo
	check: CheckList = new CheckList();
	//il secondo ogni singolo nodo
	node: CheckListNode = new CheckListNode();
	parentNode: CheckListNode = new CheckListNode();

	//prende l'indice del menu
	indice = 0;

	//valori riempiti
	valueschange: string[] = [];

	checkListInfo = new HomeForm();
	@Input() checkType?: string;

	campi: CheckListNode[] = [];
	activeTab: string = "";
	before: string = "";
	route?: string;
	printResult: any = [];
	submitSubscription: Subscription;
	canSubmit: boolean = false;

	constructor(
		private _Activatedroute: ActivatedRoute,
		public medCarService: MedCarService,
		public homeService: HomeService
	) {}

	ngOnInit() {
		this._Activatedroute.params.subscribe((paramMap) => {
			this.checkListInfo = paramMap;
			//acquisiamo la checklist tramite il servizio
			this.medCarService
				.getCheckList(paramMap.automezzo)
				.subscribe((res) => {
					this.check = res;
					this.campi = this.check.childNodes;
					// this.parentNode = 
					//console.log('campi', this.campi);
					//chiamata della funzione alla quale passiamo la lista per la ricerca dei nodi
					let node: CheckListNode | null = this.getFirstCategory(
						this.check,
						null
					);
					if (node != null) {
						this.node = node;
					}
				});
		});

		this.medCarService.canSubmit.subscribe((canSubmit) => { 		
			this.canSubmit = canSubmit;
		});
	}

	ngOnDestroy() { 
		if (this.submitSubscription) {
			this.submitSubscription.unsubscribe();
		}
	}


	//funzione che recupera o la lista o il singolo nodo della lista
	getFirstCategory(
		checkList: CheckList | null,
		inputNode: CheckListNode | null
	): CheckListNode | null {
		let result: CheckListNode | null = inputNode;
		//condizione per verificare l'esistenza della lista
		if (checkList) {
			//ciclo for scandisce la lista per recuperare i nodi
			for (let j = 0; j < checkList.childNodes.length; j++) {
				//assegna alla varibaile nodo il risulta to della ricerca del nodo con la ricorsione della funzione
				let node = this.getFirstCategory(null, checkList.childNodes[j]);
				//se la varibile nodo non è nulla, il risultato è il nodo stesso e il ciclo s'interrompe
				if (node != null && !node.isDropDownMenu) {
					result = node;
					this.parentNode = node;
					break;
				}
			}
		}
		//se il valore del parametro checklist è nullo, quindi è nulla la lista, fa la verifica sul nodo
		else {
			//se il nodo non è nullo ed è di tipo DropdownMenu fa il ciclo per cercare ulteriori nodi con la ricorsione
			if (inputNode != null && inputNode.isDropDownMenu) {
				this.parentNode = inputNode;
				for (let j = 0; j < inputNode.childNodes.length; j++) {
					//assegna alla variabile node1 il nodo sul quale ciclare per cercare gli altri nodi
					let node1 = inputNode.childNodes[j];
					//assegna alla varibaile nodo il risultato della ricerca del nodo con la ricorsione della funzione
					if (node1.isDropDownMenu) {
						let node = this.getFirstCategory(null, node1);
						if (node != null) {
							result = node;
							break;
						}
					}
				}
			}
		}
		return result;
	}
	//recupera la lista corrispondente al tab schiacciato
	getNodeByName(tabName: string) {
		for (let i = 0; i < this.campi.length; i++) {
			if (
				this.campi[i].name == tabName &&
				!this.campi[i].isDropDownMenu
			) {
				this.node = this.campi[i];
				this.activeTab = this.node.name;
				this.indice = i;
				this.parentNode = this.campi[i];
				break;
			} else {
				if (this.campi[i].isDropDownMenu) {
					let campi2 = this.campi[i].childNodes;
					for (let ii = 0; ii < campi2.length; ii++) {
						if (
							campi2[ii].name == tabName &&
							!campi2[ii].isDropDownMenu
						) {
							this.node = campi2[ii];
							this.activeTab = this.node.name;
							this.indice = i;
							this.parentNode = this.campi[i];
						} else {
							let campi3 = campi2[ii].childNodes;
							for (let jj = 0; jj < campi3.length; jj++) {
								if (campi2[ii].name == tabName) {
									this.node = campi3[jj];
									this.activeTab = this.node.name;
									this.parentNode = this.campi[i];
									break;
								}
							}
						}
					}
				}
			}
		}
	}

	onValueChange() {
		for (let item of this.check.childNodes) {
			if (!item.isDropDownMenu)
				for (let item2 of item.childNodes) {
					let name = item2.name;
					let value = item2.value;
					this.medCarService.resultForm.push({ name, value });
				}
			else if (item.isDropDownMenu)
				for (let item3 of item.childNodes) {
					for (let item4 of item3.childNodes) {
						let name = item4.name;
						let value = item4.value;
						this.medCarService.resultForm.push({ name, value });
					}
				}
		}
	}
	activateButton() {
		let isFull = true;

		if (!this.check.name && this.check.name !== null) {
			return this.check.childNodes.every(
				(child: CheckListNode) => child.value === "full"
			)
				? false
				: this.check.childNodes.some(
						(child: CheckListNode) => child.value === "full"
				  )
				? true
				: true;
		}
		return isFull;
	}
}
