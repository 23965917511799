import { CheckList } from '../main/model/checklist';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckListNode } from '../main/model/checklist_node';
import { MedCarService } from '../services/med-car.service';

@Component({
  selector: 'app-field-text-no-cri',
  templateUrl: './field-text-no-cri.component.html',
  styleUrls: ['./field-text-no-cri.component.css']
})
export class FieldTextCriNoComponent implements OnInit {
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() node: CheckListNode;
  @Input() parentNode: CheckListNode;
  @Input() fullCheckList: CheckList;

  constructor(
    private medCarService: MedCarService
  ) { }

  ngOnInit(): void {
  }

  valueChanged(e:any ){
    this.valueChange.emit(e.target.value);
    //this.medCarService.checkListValues2(this.node, this.fullCheckList);
    this.medCarService.checkListValues(this.node, this.fullCheckList);
  }

}
