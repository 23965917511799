<nav class="navbar navbar-expand-sm navbar-light bg-light ">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md">
                <img class="formatLogo cursor" 
                     src="../../assets/img/croceRossa.png" 
                     alt="logo Croce Rossa"
                     routerLink="">
            </div> 
            <!--<div class="navbar-brand text-center col-sm cursor" >
                <h4 class="font-weight-semibold spacingTitle" routerLink="">CROCE ROSSA ITALIANA</h4> -->
                <!-- <h5 routerLink="">COMITATO LOCALE DI MEDESANO</h5> 
            </div>-->
        </div>
        <ul class="nav">
            <li class="nav-item" *ngIf="this.router.url !== '/' && this.router.url !== '/admin'">
                <div class="col-md text-right"><u>SCHEDA CONTROLLO MEZZO</u></div>
            </li>
            <li class="nav-item" *ngIf="this.router.url === '/'">
              <a class="nav-link" routerLink="admin" style="color: black;">Amministrazione</a>
            </li>
        </ul>
    </div>
</nav>
