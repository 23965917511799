//struttura dati di ogni singolo nodo
export class CheckListNode {
        name: string = "";
        nComplete: string = "";
        type: string = "";
        after: string = "";
        before: string = "";
        value: any;
        options = [];
        isDropDownMenu: boolean = false; 
        childNodes: Array<CheckListNode> = [];
}