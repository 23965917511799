import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, ɵgetDebugNodeR2 } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { CheckList } from "../main/model/checklist";
import { CheckListNode } from "../main/model/checklist_node";

//URL del server
//const addURL = 'http://localhost:3000/';
//const addURL = 'https://cri-medesan.wedare.it/json/';
//const addURL = 'http://192.168.1.204:3000/';

@Injectable({
	providedIn: "root",
})
export class MedCarService {
	valueChange = new EventEmitter<any>();
	valueChangeCheck = new EventEmitter<any>();
	resultForm: { name: any; value: any }[] = [];
	canSubmit = new Subject<boolean>();

	constructor(private httpClient: HttpClient, private router: Router) {}

	/**Metodo per caricare gli oggetti elencati nel file JSON*/
	getCheckList(name: string): Observable<CheckList> {
		//ritorno dei dati del JSON, come parametro si passa l'URL del JSON costruito in modo da avere un URL generale
		return this.httpClient.get<CheckList>(
			environment.serverURL + "checklist_" + name
		);
	}

	/**Metodo per ricaricare la pagina*/
	reloadPage() {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = "reload";
	}

	/*checkListValues(node: CheckListNode) {
    let completion = {value: 'empty', label: node.name};

    if (!node.isDropDownMenu) {
      completion = node.childNodes.every(child => (child.value !== null && child.value !== "")) ? {value:  'full', label: node.name }
        : node.childNodes.some(child => (child.value !== null && child.value !== "")) ? {value:  'half', label: node.name }: completion;
    } 
    else if (node.isDropDownMenu) {
      for(let subMenu of node.childNodes) {
        subMenu.childNodes.forEach((child) => {
          completion = child.childNodes.every(child => (child.value !== null && child.value !== "")) ? {value:  'full', label: node.name }
          : child.childNodes.some(child => (child.value !== null &&  child.value !== "")) ? {value:  'half', label: node.name } : completion;
        });
      }
     }
     console.log(completion.value);
     this.isListValid.next(completion);
  }*/
	//funzione per la
	checkListValues(node: CheckListNode, fullCheckList: CheckList) {
    node.value = 'empty';
    if (!node.isDropDownMenu) {
      node.value = node.childNodes.every(child => (child.value !== null && child.value !== "")) ? 'full'
        : node.childNodes.some(child => (child.value !== null && child.value !== "")) ? 'half' : 'empty';
    } 
    else if (node.isDropDownMenu) {
      for(let subMenu of node.childNodes) {
        subMenu.childNodes.forEach((node2) => {
          node.value = node2.childNodes.every(child => (child.value !== null && child.value !== "")) ? 'full'
          : node2.childNodes.some(child => (child.value !== null &&  child.value !== "")) ? 'half' : 'empty';
        });
      }
     }
     let isFull = false;

    for (let parentNode of fullCheckList.childNodes) {
    
        isFull = parentNode.childNodes.every(child => {
          return child.value !== null && child.value !== '' && child.value !== 'half' && child.value!=='empty';
            // node.value = 'full';
        });
      
    }

    this.canSubmit.next(isFull);
  } 

	/* checkListValues2(fullCheckList: CheckList) {
		// node.value = "empty";
		let isFull = false;

    for (let parentNode of fullCheckList.childNodes) {
      if (!parentNode.isDropDownMenu) {
        isFull = parentNode.childNodes.every(child => {
          return child.value !== null && child.value !== '';
            // node.value = 'full';
        });
      } else if (parentNode.isDropDownMenu) {
        for (let child of parentNode.childNodes) {
          isFull = child.childNodes.every(subChild => subChild.value !== null && subChild.value !== '');
        }
      }
    }

    this.canSubmit.next(isFull);
	} */
}
