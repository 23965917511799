<div
  class="container-fluid-sm row align-self-center justify-content-around fixed"
  style="height: 550px"
>
  <div class="col-sm-auto cursor align-self-center" (click)="onBack()">
    <span class="material-icons" style="font-size: 30px"> arrow_back_ios </span>
  </div>
  <div class="col-sm-9">
    <form>
      <div class="container">
        <h3 class="mt-2 mb-0 p-3 title-category">{{ this.node.nComplete }}</h3>
        <table class="form-table">
          <tr *ngFor="let cat of node.childNodes" class="form-tr">
            <td  *ngIf="cat.type === 'select'">
              <app-field-select-cri
                [parentNode]="parentNode"
                [title]="cat.name"
                [options]="cat.options"
                [node]="node"
                (valueChange)="cat.value = $event"
                [value]="cat.value"
                [fullCheckList]="fullCheckList"
              ></app-field-select-cri>
            </td>
            <td *ngIf="cat.type === 'radio'">
              <app-field-check-cri
                [parentNode]="parentNode"
                [title]="cat.name"
                [name]="cat.name"
                [(value)]="cat.value"
                [node]="node"
                [fullCheckList]="fullCheckList"
              ></app-field-check-cri>
            </td>
            <td  *ngIf="cat.type === 'text'">
              <app-field-text-cri
                [parentNode]="parentNode"
                [(value)]="cat.value"
                [title]="cat.name"
                [node]="node"
                [fullCheckList]="fullCheckList"
              ></app-field-text-cri>
            </td>
            <td  *ngIf="cat.type === 'number'">
              <app-field-text-no-cri
              [parentNode]="parentNode"
              [(value)]="cat.value"
              [title]="cat.name"
              [node]="node"
              [fullCheckList]="fullCheckList"
            ></app-field-text-no-cri>
            </td>
          </tr>
        </table>
      </div>
    </form>
  </div>

  <div class="cursor position-fixed" style="height: 30px">
    <span
      class="material-icons p-2"
      style="font-size: 30px"
      (click)="openFullscreen() != closeFullscreen()"
    >
      fullscreen
    </span>
  </div>

  <div class="col-sm-auto cursor align-self-center" (click)="onNext()">
    <span class="material-icons" style="font-size: 30px">
      arrow_forward_ios
    </span>
  </div>
</div>
