import { CheckList } from './../main/model/checklist';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CheckListNode } from '../main/model/checklist_node';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.css']
})
export class FormControlComponent implements OnInit {
  @Input() node: CheckListNode=new CheckListNode();
  @Input() parentNode: CheckListNode = new CheckListNode();
  @Input() fullCheckList: CheckList;
  @Input() activeTab?: string;

  @Input() menuTab: CheckListNode[]=[];
  @Output() changeTab2 = new EventEmitter<string>();
  btnDisabled = false;

  elem: any;

  constructor(@Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.elem = document.documentElement;
  }

  onNext(){
    this.btnDisabled = false;
    let result= this.menuTab[0].name;
    for(let i=0; i<this.menuTab.length; i++)
    {
      if(this.activeTab == this.menuTab[i].name && !this.menuTab[i].isDropDownMenu){
         result= this.menuTab[i].after;
         break;
        
      }
      else{
        if (this.menuTab[i].isDropDownMenu) {
          let campi2 = this.menuTab[i].childNodes;
          for (let ii = 0; ii < campi2.length; ii++) {
            if (campi2[ii].name == this.activeTab && !campi2[ii].isDropDownMenu) {
              result = campi2[ii].after;
              break;
            } else {
              let campi3 = campi2[ii].childNodes;
              for (let jj = 0; jj < campi3.length; jj++) {
                if (campi2[ii].name == this.activeTab) {
                  result = campi3[jj].after;
                  break;
                }
              }
            }
          }
        }
      }
    }
    this.changeTab2.emit(result);
  }
  onBack(){
    let result= "";
    for(let i=0; i<this.menuTab.length; i++)
    {
      if(this.activeTab == this.menuTab[i].name && !this.menuTab[i].isDropDownMenu){
        if(this.menuTab[i].before!==null){
          result= this.menuTab[i].before;
          this.btnDisabled=false;
          break;
        } else {
          this.btnDisabled=true;
          break;
        }
        
      }
      else{
        if (this.menuTab[i].isDropDownMenu) {
          let campi2 = this.menuTab[i].childNodes;
          for (let ii = 0; ii < campi2.length; ii++) {
            if (campi2[ii].name == this.activeTab && !campi2[ii].isDropDownMenu) {
              result = campi2[ii].before;
              break;
            } else {
              let campi3 = campi2[ii].childNodes;
              for (let jj = 0; jj < campi3.length; jj++) {
                if (campi2[ii].name == this.activeTab) {
                  result = campi3[jj].before;
                  break;
                }
              }
            }
          }
        }
      }
    }  
    
    this.changeTab2.emit(result);
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      if (
        this.document.fullscreenElement ||
        this.document.webkitFullscreenElement ||
        this.document.mozFullScreenElement ||
        this.document.msExitFullscreenElement
      ){
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }}
  }
/*   fullscreen() {
    if (document.fullscreenElement === null) {
      this._fullscreen.openFullscreen();
    } else {
      this._fullscreen.closeFullscreen();
    }
  } */
}