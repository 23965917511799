
<div class="form-group">
    <label class="ngp-field-label" for="id_mandato" >{{title}}</label>
    <input type="text" 
    [ngClass]="['form-control', 'ngp-field', errorText ? 'input-wrong' : '']" 
    name="title" 
    (change)="valueChange.emit(value)"
    [(ngModel)]="value"
    [required]="required"
    >
    <p *ngIf="errorText" class="ngp-error-input">{{errorText}}</p>
</div>
