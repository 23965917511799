import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckListNode } from '../main/model/checklist_node';
import { HomeService } from '../services/home.service';

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.css'],
})

export class NavTabComponent implements OnInit {
  @Input() selectedItem: number = 0;
  @Input() menuTab: CheckListNode[]=[];
  @Output() changeTab = new EventEmitter<string>();

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
  
  }

  tabChange(name: string){
    this.changeTab.emit(name); 
  }
  
  getClass(i: number | null, completion: CheckListNode){
    if(this.selectedItem===i && i!==null){
      return "active";
    }
    else if (!completion.isDropDownMenu){
      if (completion.value==='half'){
        return 'half';
      }
      else if (completion.value==='full'){
       
        return 'full';
      }
      else {
        return "empty";
      }
    }
    else if (completion.isDropDownMenu){
      completion.value =  completion.childNodes.every(
          child =>(child.value === 'full' && child.value !==null && child.value !== "")) ? 'full'
          : completion.childNodes.some(
          child => ((child.value === 'half' || child.value === 'full')  && child.value !== null &&  child.value !== "")) ? 'half' 
          : 'empty';
          if(completion.value === 'full')
            this.homeService.activateSubmit2 = true;
      return completion.value;
    }
    else {
      return "";
    }
  }


}