<form>
  <div class="container padding-login">
    <div class="row mb-3 d-flex justify-content-center">
      <label for="inputEmail3" class="col-sm-2 col-form-label">Username</label>
      <div class="col-sm-6">
        <input type="email" class="form-control" id="inputEmail3" (change)="getValue($event)" />
      </div>
    </div>
    <div class="row mb-3 d-flex justify-content-center">
      <label for="inputPassword3" class="col-sm-2 col-form-label">
        Password</label>
      <div class="col-sm-6">
        <input type="password" class="form-control" id="inputPassword3" />
      </div>
    </div>
    <div class="d-flex justify-content-center m-5">
      <button type="submit" class="btn btn-primary">Login</button>
    </div>
  </div>
</form>