import { Injectable } from '@angular/core';
import { HomeForm } from '../main/model/homeForm';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  resultHomeForm : HomeForm [] = [];
  activateSubmit = false;
  activateSubmit2 = false;

constructor() { }
vehicleOptions= [{label:'Autoambulanza', value: 'autoambulanza'} , {label:'Automedica', value: 'automedica'}];
  shiftOptions= [{label:'Mattina', value: 'Mattina'} , {label:'Pomeriggio', value: "Pomeriggio"}, {label:'Notte', value: "Notte"}];
  driverOptions = [{label:'Mario Rossi', value: 'Mario Rossi'} , {label:'Michele Bianchi', value: 'Michele Bianchi'}, {label:'Francesco Verdi', value: 'Francesco Verdi'}];
  rescuerOptions = [{label:'Mario Viola', value: 'Mario Viola'} , {label:'Michele Neri', value: 'Michele Neri'}, {label:'Francesco Bianco', value: 'Francesco Bianco'}];
}
