<div class="container justify-content-center">
  <h1>Pagina di Amministrazione</h1>
  <!-- <wd-field-select [title]="'Nuovo Record'" [options]="scelta" (valueChange)="sceltaAdmin($event)"></wd-field-select> -->
  <form #f="ngForm" submit="add(f)">
    <wd-field-select [title]="'Nuovo Record'" [options]="scelta" (valueChange)="sceltaAdmin($event)"></wd-field-select>
    <app-newVehicle *ngIf="newVehicle"></app-newVehicle>
    <app-newRescuer *ngIf="newRescuer"></app-newRescuer>
    <button class="btn btn-primary" type="submit">Aggiungi Record</button>  
  </form>
  
</div>
