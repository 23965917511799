import { NgModule             } from '@angular/core';
import { CommonModule         } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';


@NgModule({
  declarations: [
   HeaderComponent
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule
  ],
  exports: [
    HeaderComponent    
  ]
})
export class WDAppComponentsModule { }
