
  <h4 class="ml-4 mt-4">Riassunto Form:</h4>
  <div class="ml-4 mt-4" *ngFor="let item of homeService.resultHomeForm">
      <ul>
        <li>Automezzo: {{item.automezzo}}</li>
        <li>Turno: {{item.turno}}</li>
        <li>Data: {{item.data | date: 'dd/MM/yyyy'}}</li>
        <li>Autista: {{item.autista}}</li>
        <li *ngIf="item.soccorritore1 !== ''">Soccorritore 1: {{item.soccorritore1}}</li>
        <li *ngIf="item.soccorritore2 !== '' && item.soccorritore2 !== undefined">Soccorritore 2: {{item.soccorritore2}}</li>
        <li *ngIf="item.soccorritore3 !== '' && item.soccorritore2 !== undefined">Soccorritore 3: {{item.soccorritore3}}</li>
      </ul>
  </div>
  <div class="ml-4 mt-4" *ngFor="let item2 of medCarService.resultForm">
    <ul>
      <li>{{item2.name}}: {{item2.value}}</li>
    </ul>
</div>
