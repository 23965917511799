<form #myForm="ngForm" >
    <div class="container-md">
        <wd-field-select [title]="'Scelta Automezzo'" [options]="opzioniMezzi" (valueChange)="homeResult.automezzo = $event" (change)="Confronto()"></wd-field-select>
        <wd-field-select [title]="'Scelta Turno'"  [options]="opzioniTurni" (valueChange)="homeResult.turno = $event"></wd-field-select>
        <wd-field-date [title]="'Data Turno'" [(value)]="homeResult.data"  ></wd-field-date>
        <div *ngIf="opMezzi">
            <wd-field-select [title]="'Autista'"  [options]="opzioniAutista" (valueChange)="homeResult.autista = $event"></wd-field-select>
            <wd-field-select [title]="'Soccorritore 1'" [options]="opzioniSocc" (valueChange)="homeResult.soccorritore1 = $event"></wd-field-select>
            <div *ngIf="ambCond">
                <wd-field-select [title]="'Soccorritore 2'"  [options]="opzioniSocc" (valueChange)="homeResult.soccorritore2 = $event"></wd-field-select>
                <wd-field-select [title]="'Soccorritore 3'"  [options]="opzioniSocc" (valueChange)="homeResult.soccorritore3 = $event"></wd-field-select>
            </div>
        </div>
        <div class="col text-center m-4 fixed-bottom">
            <button class="btn btn-success" [disabled]="!opMezzi" [routerLink]="[this.route, homeResult]" (click)="onsubmit()"  >Avanti</button>
        </div> 
    </div>
    
</form>

