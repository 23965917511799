import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-newRescuer',
  templateUrl: './newRescuer.component.html',
  styleUrls: ['./newRescuer.component.css']
})
export class NewRescuerComponent implements OnInit {

  dataNascita!: Date;

  
  constructor() { }

  ngOnInit() {
  }

}
