import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home.service';
import { MedCarService } from '../services/med-car.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  constructor(public medCarService: MedCarService, public homeService: HomeService) { }

  ngOnInit() {

  }

}
