 

<div class="form-group" *ngIf="options.length">
    <label class="ngp-field-label">{{title}}</label>
    <select class="form-control ngp-field"
    (change)="valueChange.emit(selected)"
    [(ngModel)]="selected"
    >
        <option  *ngFor = "let option of options" [ngValue]="option.value" [disabled]="option.disabled" >
            {{option.label}}
        </option>
        </select>
        <p *ngIf="errorText" class="ngp-error-input">{{errorText}}</p>
</div>


