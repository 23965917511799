<table>
    <tr>
        <td scope="row" class="pl-2">{{title}}</td>
        <td class="col-md-4 col-sm-2 p-1" rowspan="2">
            <div class="form-group text-center justify-content-center mb-0">
                <input type="text" class="form-control form-control-sm" name="{{title}}" ngModel pattern="^[a-z]{4,8}"
                (keyup)="valueChanged($event)" placeholder="{{value}}">
            </div>  
        </td>
    </tr>
</table>