import { NgModule             } from '@angular/core';
import { CommonModule         } from '@angular/common';
import { FieldTextComponent } from "./field-text/FieldTextComponent";
import { FieldRadioComponent  } from './field-radio/field-radio.component';
import { FieldSelectComponent } from './field-select/field-select.component';
import { FieldButtonComponent } from './field-button/field-button.component';
import { FieldDateComponent   } from './field-date/field-date.component';
import { FieldNumberComponent } from './field-number/field-number.component';
import { FieldCheckComponent  } from './field-check/field-check.component';
import {FormsModule} from '@angular/forms';
import { SectionTitleComponent } from './section-title/section-title.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    FieldTextComponent,
    FieldRadioComponent,
    FieldSelectComponent,
    FieldButtonComponent,
    FieldDateComponent,
    FieldNumberComponent,
    FieldCheckComponent,
    SectionTitleComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule
  ],
  exports: [
    FieldTextComponent, 
    FieldRadioComponent, 
    FieldSelectComponent, 
    FieldButtonComponent, 
    FieldDateComponent, 
    FieldNumberComponent, 
    FieldCheckComponent,
    SectionTitleComponent,
    
  ]
})
export class WDFormFieldsModule { }
