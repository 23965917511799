import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';



@Component({
  selector: 'wd-field-text',
  templateUrl: './field-text.component.html',
  styleUrls: ['./field-text.component.css']
})
export class FieldTextComponent implements OnInit {

  /**holds current value of input */
  @Input() value: string = "";
  @Input() errorText?: string;

  /**invoked when model has been changed
   */
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();


  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
