import { Component, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { OptionList } from 'src/app/main/model/options';
import { BackOfficeService } from '../backOffice.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  scelta: OptionList[] = [];
  newVehicle = false;
  newRescuer = false;
  

 
  constructor(private backOfficeService: BackOfficeService) { }

  ngOnInit() {
    this.scelta = this.backOfficeService.scelta;
  }

  sceltaAdmin(scelta: number){
    if(scelta === 1)
    {
      this.newVehicle= true;
      this.newRescuer = false;
    }else if(scelta === 2){
      this.newVehicle = false;
      this.newRescuer = true;
    }
  }

  add(form: NgForm){
    console.log(form.value)
    alert(form);
  }

}
